import React from "react";
import {graphql} from "gatsby";
import styled from "@emotion/styled";
import colors from "../lib/colors";
import Document from "../components/Document";
import DefaultLayout from "../components/DefaultLayout";
import Hero from "../components/bricks/Hero";

const ContentContainer = styled("div")({
  backgroundColor: colors.brightBg,
  color: colors.dark,
  padding: "4rem 2rem",
  "@media(max-width: 600px)": {padding: "4rem 1rem"},
});
const Content = styled(Document)({
  margin: "0 auto",
  maxWidth: "35rem",
});

const SimplePage = ({data: {markdownRemark: post}, location}) => (
  <DefaultLayout
    title={post.frontmatter.title}
    location={location}
    description={post.frontmatter.description}
  >
    <Hero size="sm" title={post.frontmatter.title} subline={post.frontmatter.subline} />
    <ContentContainer>
      <Content dangerouslySetInnerHTML={{__html: post.html}} />
    </ContentContainer>
  </DefaultLayout>
);

export default SimplePage;

export const query = graphql`
  query SimplePageContentBySlug($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      id
      html
      frontmatter {
        title
        description
        subline
      }
    }
  }
`;
